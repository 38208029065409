<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="品目登録" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container l-box">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>
        <div class="l-contents">
          <div class="l-box">
            <h3 class="c-lead icon">品目情報</h3>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>廃棄物種類</label>
            </div>
            <div class="c-inputWrap__items">
              <Selectbox
                class="l-area"
                :value.sync="form.jwnetItemId"
                :validation="validateJustBlank"
                :isValid.sync="validate.jwnetItemId.isValid"
                :errMsg.sync="validate.jwnetItemId.errMsg"
              >
                <option
                  v-for="jwnetItem in jwnetItems"
                  :key="jwnetItem.id"
                  :value="jwnetItem.id"
                >
                  {{ jwnetItem.name }}
                </option>
              </Selectbox>
            </div>
          </div>
          <div v-for="(cba, index) in form.cbaItemList" :key="index">
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>CBA品目名称</label>
              </div>
              <div class="c-inputWrap__items u-space-btwn">
                <InputText
                  :value.sync="cba.cbaItemName"
                  inputTyle="text"
                  placeholder="CBA品目名称を入力"
                  tooltip="CBA品目名称を入力してください"
                  :validation="validateMaxLen"
                  :validateArg="50"
                  :isValid.sync="validate.cbaItemList[index].cbaItemName.isValid"
                  :errMsg.sync="validate.cbaItemList[index].cbaItemName.errMsg"
                />
                <div>
                  <div
                    class="c-btn secondary sm delete"
                    @click="deleteCbaList(index)"
                  >
                    削除
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>備考</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="l-area">
                  <MyTextarea
                    :value.sync="cba.remarks"
                    rows="6"
                    placeholder="備考を入力"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="l-each sm l-box">
            <div class="c-btn secondary func add" @click="addCbaList">
              CBA品目名称を追加
            </div>
          </div>
        </div>
      </div>
      <div class="l-btns l-container">
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">戻る</div>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="formHandler">登録</div>
        </div>
      </div>
    </main>

    <!-- 登録モーダル -->
    <div class="c-modal" v-show="isDisplayConfirm">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          品目を登録します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="closeConfirmModalHandler">戻る</div>
          <div class="c-btn primary small" @click="registerHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import InputText from "@/components/parts/input/InputText";
import MyTextarea from "@/components/parts/input/MyTextarea";
import validation from "@/mixin/validation";
import formValue from "../mixins/formValue";
import callApi from "../mixins/callApi";
import formValidation from "../mixins/form/formValidation";
import Selectbox from "@/components/parts/input/Selectbox";
import axios from "axios";
import {API_URL} from "@/const";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "cbaitem-input",
  components: {
    ModalFooterButtons,
    InputText,
    Selectbox,
    MyTextarea,
  },
  mixins: [validation, formValue, callApi, formValidation],
  data() {
    return {
      isDisplayConfirm: false,
      errMsgs: [],
      processing: false,
    };
  },

  methods: {
    closeConfirmModalHandler() {
      this.isDisplayConfirm = false;
    },

    registerHandler() {
      this.processing = true; // 2重submit防止
      axios
        .post(API_URL.WASTEITEM.CBAITEM, this.form)
        .then(() => {
          this.$router.push("/cbaitem");
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isDisplayConfirm = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    // フォームの登録押下
    formHandler() {
      this.errMsgs = [];

      if (!this.runValidate()) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      this.isDisplayConfirm = true;
    },

    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.go(-1);
    },
    // CBA入力欄追加
    addCbaList() {
      const newList = {
        cbaItemName: "",
        remarks: "",
      };
      const newValidate = {
        cbaItemName: { isValid: true, errMsg: "" },
      };
      this.form.cbaItemList = [...this.form.cbaItemList, newList];
      this.validate.cbaItemList = [...this.validate.cbaItemList, newValidate];
    },
    // CBA入力欄削除
    deleteCbaList(index) {
      this.form.cbaItemList.splice(index, 1);
      this.validate.cbaItemList.splice(index, 1);
    },
  },
  mounted() {
    if (this.$route.query.jwnetItemId) {
      this.form.jwnetItemId = this.$route.query.jwnetItemId;
    }
    this.getJwnetItemTypeApi();
  },
};
</script>
